










































import { RouteName } from "@/router";
import { ActionTypes } from "@/store/action-types";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      switchOrgRoute: RouteName.SWITCH_ORG
    };
  },
  methods: {
    logout() {
      this.$storeTyped.dispatch(ActionTypes.LOGOUT_USER);
    }
  }
});
